import React  from 'react';
import Styled from 'styled-components/macro';

const FooterContainer = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-size: 1.2rem;
    padding: 3rem 0;
    background-color: ${(props) => (props.green ? props.theme.colors.secondary : props.theme.colors.primary)};
`;

const FooterSpan = Styled.span`
    margin-right: 1rem;
`;

const ContactNavLink = Styled.a`
    :before {
        content: "Contact";
    }

    &:hover {
        text-decoration: underline;
    }

    &:hover:before {
        content: "bestdesignsoftware@gmail.com";
    }
`;

const Footer = ({green}) => (
    <FooterContainer green={green}>
        <FooterSpan>
            © {new Date().getFullYear()}
        </FooterSpan>
        <FooterSpan>-</FooterSpan>
        <FooterSpan>
            Best Design Software
        </FooterSpan>
        <FooterSpan>-</FooterSpan>
        <ContactNavLink href="mailto:bestdesignsoftware@gmail.com"></ContactNavLink>
    </FooterContainer>
);

export default Footer;              