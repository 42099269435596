import React from 'react';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';

const Meta = ({props}) => {
  const title = get(props, 'title', 'Best Design Software');
  const description = get(props, 'description', 'Find the best design tool for your needs');
  const url = get(props, 'url', '');

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:url" content={`https://bestdesignsoftware.io${url}`} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://bestdesignsoftware.io/opengraph-2021-06-26-09-35.png" />

      <link rel="canonical" href={`https://bestdesignsoftware.io${url}`} />
    </Helmet>
  );
}

export default Meta;
