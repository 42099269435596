import * as React from 'react';
import { ThemeProvider } from 'styled-components/macro';
import theme, { GlobalStyle } from '../theme';

const App = ({ green, children }) => {
  React.useEffect(() => {
  }, []);
  return (
    <>
    <ThemeProvider theme={theme}>
      <GlobalStyle green={green} />
      { children }
    </ThemeProvider>
    </>
  );
};

export default App;
