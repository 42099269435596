import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';

const theme = {
  fonts: {
    regular: 'Poppins, Helvetica, Helvetica Neue, Arial',
  },
  global: {
    width: '90rem',
    narrowWidth: '50rem',
  },
  colors: {
    primary: '#cec7fe',
    secondary: '#c8fdcd',
    white: '#ffffff',
    black: '#24273f',
  }
};

/* eslint-disable no-unused-expressions */
export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  html {
    font-size: 10px;
    height: 100%;
  }

  body {
    font-family: ${theme.fonts.regular};
    background: ${(props) => (props.green ? props.theme.colors.secondary : props.theme.colors.primary)};
    color: ${theme.colors.black};
    font-size: 14px;
    line-height: 1.5;
    font-variant-ligatures: no-common-ligatures;
  }

  .no-scroll {
    overflow: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
  }

  a {
    color: ${theme.colors.black};
    text-decoration: none;
    transition: .3s;
    outline : none;
  }

  a:hover, a:focus, a:active {
    color: ${theme.colors.black};
  }
`;

export default theme;
