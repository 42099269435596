import * as React from 'react';
import Header from './header';
import Footer from './footer';
import App from './app';

const Layout = ({ children, green }) => (
  <App green={green}>
    <Header/>
    { children }
    <Footer green={green}/>
  </App>
);

export default Layout;
