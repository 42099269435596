import * as React from 'react';
import { Component } from 'react';
import Styled from 'styled-components/macro';

import Content from './content'
import NavLink from './navLink'

const HeaderContainer = Styled.div`
  padding: 2rem 0;
  background-color: ${(props) => props.theme.colors.white};
`;

const Nav = Styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-out;

    ${(props) => props.isOpen && `
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    `}
  }
`;

const NavLeft = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NavRight = Styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const ExtendedNavLink = Styled(NavLink)`
  font-size: 1.5rem;
  margin-left: 2rem;
  font-weight: 600;

  @media (max-width: 600px) {
    margin: auto;
  }
`;

const ExtendedLogoLink = Styled(NavLink)`
  display: block;
  width: 52px;
  height: 42px;

  &:hover, &:focus, &:focus, &:active {
    &:before {
      width: 0%;
    }
  }

  @media (max-width: 600px) {
    margin: 10px auto;
    padding-left: 5px;
  }
`;

const Logo = Styled.img`
  display: block;
  height: 42px;
  margin: 0 1rem 0 0;
`;

const ExtendedNavLinkCTA = Styled(ExtendedNavLink)`
  display: block;

  &:hover, &:focus, &:focus, &:active {
    &:before {
      width: 0%;
    }
  }

  @media (max-width: 600px) {
    margin-top: 1rem;
  }
`;

const ButtonCTA = Styled.div`
    width: auto;
    padding: 0.5rem 2rem;
    display: block;
    align-items: center;
    justify-content: center;
    font-family: ${(props) => props.theme.fonts.regular};
    background-color: ${(props) => props.theme.colors.secondary};
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.black};
    cursor: pointer;
    text-align: center;
    border-radius: 10px;

    &:hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.black};
    }

    @media (max-width: 900px) {
        width: 100%;
        margin: 0 0 2rem 0;
    }
`;

const MenuToggle = Styled.div`
  display: none;
  cursor: pointer;
  margin-top: 8px;

  @media (max-width: 600px) {
    display: block;

    ${(props) => props.isOpen && `
      display: none;
    `}
  }
`;

const CrossToggle = Styled.div`
  display: none;
  cursor: pointer;
  margin-top: 7px;

  @media (max-width: 600px) {
    display: none;

    ${(props) => props.isOpen && `
      display: block;
    `}
  }
`;

export default class extends Component {
  state = {
    isTop: true,
    isOpen: false,
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    return (
  <HeaderContainer>
    <Content>
      <MenuToggle onClick={this.handleToggle} isOpen={this.state.isOpen}>
        <svg viewBox="0 0 60 60" width="30" height="25">
          <rect width="60" height="10"></rect>
          <rect y="20" width="60" height="10"></rect>
          <rect y="40" width="60" height="10"></rect>
        </svg>
      </MenuToggle>

      <CrossToggle onClick={this.handleToggle} isOpen={this.state.isOpen}>
        <svg viewBox="0 0 60 60" width="40" height="30">
          <rect width="60" height="10" transform="rotate(45)"></rect>
          <rect x="-35" y="25" width="60" height="10" transform="rotate(-45)"></rect>
        </svg>
      </CrossToggle>

      <Nav isOpen={this.state.isOpen}>
        <NavRight>
          <ExtendedLogoLink to="/">
            <Logo src="/icon.png" alt="logo image for best design software website" />
          </ExtendedLogoLink>
          <ExtendedNavLink to="/app/">Tool Picker</ExtendedNavLink>
          <ExtendedNavLink to="/highlight/">Tool Highlight</ExtendedNavLink>
          <ExtendedNavLink to="/guides/">Guides</ExtendedNavLink>
        </NavRight>

        <NavLeft>
          <ExtendedNavLinkCTA to="http://eepurl.com/hzXImb"><ButtonCTA>Signup to Newsletter</ButtonCTA></ExtendedNavLinkCTA>
        </NavLeft>
      </Nav>
      
    </Content>

  </HeaderContainer>
  );
  }
};