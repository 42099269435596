import * as React from 'react';
import { Link } from 'gatsby';

import Styled from 'styled-components/macro';


const HybridLink = ({ children, ...props }) => {
    if (props.to.includes('http')) {
      // eslint-disable-next-line react/jsx-filename-extension
      return <a href={props.to} {...props}>{children}</a>;
    }
  
    // @ts-ignore
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Link {...props}>{children}</Link>;
  };
  
  const NavLink = Styled(HybridLink)`
    position: relative;
    display: inline-flex;
    font-size: 1.6rem;
    padding: 0;
    overflow: hidden;
    font-weight: 500;
    align-items: center;
    height: 4rem;
  
    &:hover, &:focus, &:focus, &:active {
      color: ${(props) => props.theme.colors.black};
    }
  
    &:before {
      content: "";
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      background: ${(props) => (props.theme.colors.black)};
      transition: height .3s, width .1s;
      top: auto;
      bottom: 0;
      left: 50%;
      height: 0.6rem;
      width: 0;

      &:hover, &:focus, &:focus, &:active {
        &:before {
          width: 100%;
        }
      }
  
      ${(props) => props.active && `
        &:before {
          width: 100%;
        }
      `}
    }
  
    &:before {
        top: auto;
        bottom: 0;
        left: 50%;
        transform: translate3d(-50%, 50%, 0);
        height: 0.6rem;
        width: 0;
      }
  
      &:hover, &:focus, &:focus, &:active {
        &:before {
          width: 100%;
        }
      }
  
      ${(props) => props.active && `
        &:before {
          width: 100%;
        }
      `}
`;

export default NavLink;